<script>
import i18n from '@/shared/plugins/vue-i18n';
import { server } from '../../../const';
import { getToken } from '../../../shared/services/jwt.service';
import BaseButton from '../../../shared/components/BaseButton.vue';
import Notify from '../../../shared/utils/notify';

const store = {
  state: {
    loading: false,
    data: null,
  },
};
export default {
  components: {
    BaseButton,
  },
  data() {
    return {
      store,
      model: {},
    };
  },
  methods: {
    getData() {
      const listStudPath = '/WebGetReferralsTable.php';
      const data = new FormData();

      fetch(server + listStudPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'ok') {
            store.state.data = json;
          }
        });
    },
    copy() {
      navigator.clipboard.writeText(store.state.data.link);
      Notify('success', i18n.t('GENERAL.COPIED_LINK'));
    },
  },
  beforeMount() {
    this.getData();
  },
};
</script>
<template lang="html">
  <div>
    <b-row class="chat">
      <b-col md="12" lg="12" style="margin-bottom: 24px">
        <div class="title-0-medium-28 basic-1"
             style="margin-right: 40px;display: flex;align-items: center;">
          {{ $t("GENERAL.AFFILIATION_PROGRAM") }}
        </div>
      </b-col>
    </b-row>
    <b-row style="margin: 0 -12.5px!important;" v-if="store.state.data">
      <b-col md="3" sm="12">
        <div class="card" style="">
          <h6 style="background-color: #D0EAF6; border-radius: 0.42rem 0.42rem 0 0;
          text-align: center; padding: 20px; font-weight: bold;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.20));">

            {{ $t("AFFILIATION.TITLE_1") }}
          </h6>

          <div class="rows-container">
            <div>
              <div class="row-container">
                <div class="icon-container">
                  <div class="icon">
                    <unicon name="subject" fill="#1694D0"/>

                  </div>
                </div>
                <div class="text-container">
                  {{ $t("AFFILIATION.TEXT_1_1") }}

                  <div style="margin-top: 8px">
                    <div>
                      <div
                        v-on:click="copy()">
                        <BaseButton
                          :variant="'light'"
                          type="button">

                          {{ $t("GENERAL.COPY_LINK") }}
                        </BaseButton>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </b-col>
      <b-col md="3" sm="12">
        <div class="card" style="">
          <h6 style="background-color: #FFFAE8; border-radius: 0.42rem 0.42rem 0 0;
          text-align: center; padding: 20px; font-weight: bold;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));">

            {{ $t("AFFILIATION.TITLE_2") }}
          </h6>

          <div class="rows-container">
            <div>
              <div class="row-container">
                <div class="icon-container">
                  <div class="icon">
                    <unicon name="subject" fill="#FDAB3D"/>

                  </div>
                </div>
                <div class="text-container">
                  {{ $t("AFFILIATION.TEXT_2_1") }}

                </div>
              </div>
            </div>
            <div>
              <div class="row-container">
                <div class="icon-container">
                  <div class="icon">
                    <unicon name="subject" fill="#FDAB3D"/>

                  </div>
                </div>
                <div class="text-container">
                  {{ $t("AFFILIATION.TEXT_2_2") }}
                </div>
              </div>
            </div>
            <div>
              <div class="row-container">
                <div class="icon-container">
                  <div class="icon">
                    <unicon name="subject" fill="#FDAB3D"/>

                  </div>
                </div>
                <div class="text-container">
                  {{ $t("AFFILIATION.TEXT_2_3") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="3" sm="12">
        <div class="card" style="">
          <h6 style="background-color: #D0EAF6; border-radius: 0.42rem 0.42rem 0 0;
          text-align: center; padding: 20px; font-weight: bold;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));">

            {{ $t("AFFILIATION.TITLE_3") }}
          </h6>

          <div class="rows-container">
            <div>
              <div class="row-container">
                <div class="icon-container">
                  <div class="icon">
                    <unicon name="subject" fill="#1694D0"/>

                  </div>
                </div>
                <div class="text-container">
                  {{ $t("AFFILIATION.TEXT_3_1") }}
                  <div style="margin-top: 8px">
                    <div>
                      <div
                        v-on:click="copy()">
                        <BaseButton
                          :variant="'light'"
                          type="button">

                          {{ $t("GENERAL.COPY_LINK") }}
                        </BaseButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row-container">
                <div class="icon-container">
                  <div class="icon">
                    <unicon name="subject" fill="#1694D0"/>

                  </div>
                </div>
                <div class="text-container">
                  {{ $t("AFFILIATION.TEXT_3_2") }}
                </div>
              </div>
            </div>
            <div>
              <div class="row-container">
                <div class="icon-container">
                  <div class="icon">
                    <unicon name="subject" fill="#1694D0"/>

                  </div>
                </div>
                <div class="text-container">
                  {{ $t("AFFILIATION.TEXT_3_3") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="3" sm="12">
        <div class="card" style="">
          <h6 style="background-color: #FFFAE8; border-radius: 0.42rem 0.42rem 0 0;
          text-align: center; padding: 20px; font-weight: bold;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));">

            {{ $t("AFFILIATION.TITLE_4") }}
          </h6>

          <div class="rows-container">
            <div>
              <div class="row-container">
                <div class="icon-container">
                  <div class="icon">
                    <unicon name="subject" fill="#FDAB3D"/>

                  </div>
                </div>
                <div class="text-container">
                  {{ $t("AFFILIATION.TEXT_4_1") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="12">

        <div class="title-0-medium-28 basic-1"
             style="margin: 20px 0;display: flex;align-items: center;justify-content: center">
          {{ $t("AFFILIATION.RESIDENCE_TABLE") }}
        </div>
        <b-table-simple class="custom-table-affiliation">
          <b-thead>
            <b-tr>
              <b-th style="width: 16.4%;">
                {{ $t("AFFILIATION.MODAL") }}
              </b-th>
              <b-th style="width: 16.4%;">
                {{ $t("AFFILIATION.NO_OF_USERS") }}
              </b-th>
              <b-th style="width: 16.4%;">
                {{ $t("AFFILIATION.NO_OF_PAYMENTS") }}
              </b-th>
              <b-th style="width: 16.4%;">
                {{ $t("AFFILIATION.TOTAL_REVENUE") }}
              </b-th>
              <b-th style="width: 16.4%;">
                {{ $t("AFFILIATION.INCOME_AVAILABLE") }}
              </b-th>
              <b-th style="width: 18%;">
                {{ $t("AFFILIATION.POSIBILITY_OF_USE") }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td  style="width: 16.4%;background-color: #D0EAF6">
                {{ $t("AFFILIATION.ADD_TO_GROUPS") }}
              </b-td>
              <b-td  style="width: 16.4%;">
                {{store.state.data.referrals_manual.amount}}
              </b-td>
              <b-td  style="width: 16.4%;">
                {{store.state.data.referrals_manual.amount_paid}}
              </b-td>
              <b-td  style="width: 16.4%;">
                {{store.state.data.referrals_manual.earnings}}
              </b-td>
              <b-td  style="width: 16.4%;">
                {{store.state.data.referrals_manual.earnings_available}}
              </b-td>
              <b-td style="width: 18%;">
                <strong>{{ $t("AFFILIATION.PAYMENT_FOR_1000_MESSAGES") }}</strong>
                <br/>
                <BaseButton
                  :variant="'primary'"
                  type="button">

                  <unicon fill="white" height="16" width="16" name="wallet"/>
                  {{ $t("AFFILIATION.PAY") }}
                </BaseButton>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td  style="width: 16.4%;background-color: #FFFAE8" >
                {{ $t("AFFILIATION.SEND_THE_LINK") }}
              </b-td>
              <b-td  style="width: 16.4%;">
                {{store.state.data.referrals_link.amount}}
              </b-td>
              <b-td  style="width: 16.4%;">
                {{store.state.data.referrals_link.amount_paid}}
              </b-td>
              <b-td  style="width: 16.4%;">
                {{store.state.data.referrals_link.earnings}}
              </b-td>
              <b-td  style="width: 16.4%;">
                {{store.state.data.referrals_link.earnings_available}}
              </b-td>
              <b-td style="width: 18%;">
                <strong>{{ $t("AFFILIATION.WITHDRAW_MONEY") }}</strong>
                {{ $t("AFFILIATION.MINIMUM_200") }}

                <br/>
<!--                <div class="btn btn-orange" >

                </div>-->
                <BaseButton
                  :variant="'secondary'"
                  type="button">

                  <unicon fill="white" height="16" width="16" name="money-withdraw"/>
                  {{ $t("AFFILIATION.WITHDRAW") }}
                </BaseButton>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>
<style lang="scss">
.card{
  height: 100%;
}
.rows-container{
  padding: 30px;
  .row-container{
    display: flex;
    margin-bottom: 16px;
    .icon-container{
      .icon{
        width: 30px;
        height: 30px;
        border-radius: 8px;
        background: #F2F2F2;
        margin-right: 14px;
        justify-content: center;
        display: flex;
        vertical-align: center;
      }
    }
    .text-container{
      color: #4F4F4F;

      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.154px;
    }
  }
}
.custom-table-affiliation{
  border-radius: 12px;
  background: var(--basic-white, #FFF);
  --bs-table-accent-bg: unset;
  /* Shadow */
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10);
  color: #333;
  font-family: IBM Plex Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
  thead{
    border-radius: 12px;
    background: #F2F2F2;
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.10);
  }

  th:first-child{

    border-top-left-radius: 12px;
  }
  th:last-child{

    border-top-right-radius: 12px;
  }

  th,td{
    padding: 20px 0.5rem;
    justify-content: center;
    text-align: center;
    height: fit-content;
    vertical-align: center;
    background-color: unset;
    border-bottom-color: #E0E0E0!important;
    border-bottom-width: 1px;
  }
  tr{
    border: 0 none;
    background: unset;
  }
  th:not(:last-child) ,td:not(:last-child){
    border-right-color: #E0E0E0!important;
    border-right-width: 1px;
  }
}
</style>
